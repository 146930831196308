<kendo-floatingactionbutton
  kendoPopoverAnchor
  showOn="none"
  #anchor="kendoPopoverAnchor"
  [popover]="chatPopover"
  (click)="anchor.toggle()"
  class="chat-bot-floating-action-button"
  size="none"
>
  <ng-template kendoFloatingActionButtonTemplate>
    <kendo-avatar
      size="medium"
      imageSrc="https://kehe-connect.s3.amazonaws.com/prod/public_common/chat-icon.png"
      class="chat-bot-avatar"
    ></kendo-avatar
    >Ask Goodness Guru™
  </ng-template>
</kendo-floatingactionbutton>

<kendo-popover
  #chatPopover
  position="top"
  [callout]="false"
  [animation]="{ type: 'expand', direction: 'up', duration: '400' }"
  (shown)="onPopoverShown()"
  (hidden)="onPopoverHidden()"
>
  <ng-template kendoPopoverTitleTemplate>
    <i class="d-flex justify-content-end fas fa-times close-icon" (click)="anchor.toggle()"></i>
  </ng-template>
  <ng-template kendoPopoverBodyTemplate>
    <kendo-chat
      class="chat-bot"
      [messages]="messages$ | async"
      [user]="user$ | async"
      (sendMessage)="addUserMessage($event)"
      (executeAction)="onCustomSuggestedAction($event)"
    ></kendo-chat>
  </ng-template>
  <ng-template kendoPopoverActionsTemplate
    ><p class="chatbot-disclaimer">
      This content has been generated by an artificial intelligence language
      model. While KeHE strives for accuracy and quality, please note that the
      information provided may not be error-free or completely up-to-date. KeHE
      recommends Suppliers independently verify the content and consult with
      their KeHE representatives for clarification and additional information.
      KeHE does not assume any liability for the use or interpretation of this
      content.
    </p>
  </ng-template>
</kendo-popover>
