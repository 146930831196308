import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Action, User } from '@progress/kendo-angular-conversational-ui';
import { ChatBotService } from './chat-bot.service';
import {
  exhaustMap,
  filter,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { Store } from '@ngrx/store';
import {
  ChatBotAuthorization,
  ConnectUser,
  ChatBotMessage,
  ChatBotAuthorizeRequest,
  ChatBotSendMessageRequest,
  ChatBotSendMessageResponse,
  ChatBotUser,
  ChatBotSendFeedbackRequest,
  ActionTypes
} from './interfaces';
import { Observable, timer } from 'rxjs';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import * as JWT from 'jwt-decode';

export interface ChatBotState {
  chatUser: ChatBotUser;
  chatAuthentication: ChatBotAuthorization;
  isChatOpen: boolean;
  messages: ChatBotMessage[];
}

export const BOT_USER: User = {
  id: 0,
  name: 'goodness-guru',
  avatarUrl:
    'https://kehe-connect.s3.amazonaws.com/prod/public_common/chat-icon.png'
};

export const FEEDBACK_ACTIONS: Action[] = [
  { type: ActionTypes.Feedback, title: '👍 This was helpful', value: true },
  { type: ActionTypes.Feedback, title: '👎 This was not helpful', value: false }
];

@Injectable()
export class ChatBotStore extends ComponentStore<ChatBotState> {
  constructor(
    private chatBotService: ChatBotService,
    private store: Store,
    private phoenixToastService: PhoenixToastService
  ) {
    super({
      chatUser: undefined,
      chatAuthentication: undefined,
      isChatOpen: false,
      messages: [
        {
          author: BOT_USER,
          text: "Hi, I'm the Goodness Guru™. Ask me anything about supplier contracts, supplier onboarding, KeHE CONNECT BI®, reports, dashboards, or anything related to KeHE from our website."
        }
      ]
    });
  }

  readonly messages$ = this.select((state) => state.messages);

  readonly selectUser$ = this.select(
    this.store.select(AuthenticationSelectors.selectUserEmail),
    this.store.select(AuthenticationSelectors.selectUserFullName),
    (email, name) => {
      const user: User = {
        id: email,
        name
      };
      return user;
    }
  );

  // selectRequestUser (pt 1 chat request)
  readonly selectConnectUser$ = this.select(
    this.store.select(AuthenticationSelectors.selectUserFirstName),
    this.store.select(AuthenticationSelectors.selectUserLastName),
    this.store.select(AuthenticationSelectors.selectUserFullName),
    this.store.select(AuthenticationSelectors.selectUserEmail),
    this.store.select(AuthenticationSelectors.selectIsInternalUser),
    (firstName, lastName, fullName, email, isInternalUser) => {
      const connectUser: ConnectUser = {
        Name: fullName,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        IsInternalUser: isInternalUser,
        IsConnectChatbotAuthorized: true
      };
      return connectUser;
    }
  );

  // select ChatUser (pt 2 chat request)
  readonly selectChatUser$ = this.select((state) => state.chatUser);

  // select AppAuth (pt 3 chat request)
  readonly selectAppAuth$ = this.select((state) => state.chatAuthentication);

  // authorize request
  readonly selectChatAuthorizeRequest$ = this.select(
    this.selectConnectUser$,
    this.selectChatUser$,
    this.selectAppAuth$,
    (connectUser, chatUser, chatAuth) => {
      const request: ChatBotAuthorizeRequest = {
        RequestUser: connectUser,
        ChatUser: chatUser,
        AppAuth: chatAuth
      };
      return request;
    }
  );

  readonly selectChatTokenExpiration$ = this.select(
    this.selectAppAuth$,
    (auth) => {
      if (!auth || !auth.IdToken) {
        return null;
      }
      const decodedToken = JWT(auth.IdToken);
      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(decodedToken.exp - 300);
      return expirationDate;
    }
  );

  readonly selectLatestUserMessage$ = this.select(
    this.messages$,
    this.selectUser$,
    (messages, user) =>
      messages?.filter((message) => message.author.id === user.id).pop()
  );

  readonly selectSendMessageRequest$ = this.select(
    this.selectConnectUser$,
    this.selectChatUser$,
    this.selectAppAuth$,
    this.selectLatestUserMessage$,
    (connectUser, chatUser, chatAuth, message) => {
      const request: ChatBotSendMessageRequest = {
        RequestUser: connectUser,
        ChatUser: chatUser,
        AppAuth: chatAuth,
        RequestMessage: message?.text
      };
      return request;
    }
  );

  readonly selectSendFeedbackRequest$ = this.select(
    this.selectAppAuth$,
    this.messages$,
    (auth, messages) => {
      const message = messages[messages.length - 1];
      return <ChatBotSendFeedbackRequest>{
        appAuth: auth,
        conversationId: message.conversationId,
        systemMessageId: message.systemMessageId,
        userMessageId: message.userMessageId,
        requestMessage: message.text
      };
    }
  );

  readonly addUserMessage = this.updater((state, message: ChatBotMessage) => ({
    ...state,
    messages: [
      ...state.messages,
      message,
      { author: BOT_USER, typing: true, timestamp: new Date() }
    ]
  }));

  readonly authorizeUserForChat = this.effect(() => {
    return this.messages$.pipe(
      withLatestFrom(this.selectChatAuthorizeRequest$),
      filter(
        ([messages, request]) =>
          !!messages &&
          messages[messages.length - 1].typing === true &&
          !request.AppAuth
      ),
      exhaustMap(([messages, request]) =>
        this.chatBotService.authorize(request).pipe(
          tapResponse(
            (response) => {
              this.patchState(() => ({
                chatUser: response.ChatUser,
                chatAuthentication: response.AppAuth
              }));
            },
            () => {
              this.patchState((state: ChatBotState) => ({
                messages: [
                  ...state.messages,
                  {
                    author: BOT_USER,
                    text: 'An error has occurred and I was unable to complete your request. Please try again.'
                  }
                ]
              }));
            }
          )
        )
      )
    );
  });

  readonly sendMessage = this.effect(() => {
    return this.selectSendMessageRequest$.pipe(
      filter((request) => !!request.AppAuth),
      switchMap((request) =>
        this.chatBotService.sendMessage(request).pipe(
          tapResponse(
            (response: ChatBotSendMessageResponse) => {
              this.patchState((state: ChatBotState) => ({
                messages: [
                  ...state.messages,
                  {
                    conversationId: response.conversationId,
                    systemMessageId: response.systemMessageId,
                    userMessageId: response.userMessageId,
                    author: BOT_USER,
                    text: response.Messages.ResponseMessage,
                    suggestedActions:
                      response.Messages.ResponseLinkObjects?.map((link) => {
                        return <Action>{
                          type: ActionTypes.Link,
                          title: link.title,
                          value: link
                        };
                      }).concat(FEEDBACK_ACTIONS) ?? FEEDBACK_ACTIONS
                  }
                ]
              }));
            },
            () => {
              this.patchState((state: ChatBotState) => ({
                messages: [
                  ...state.messages,
                  {
                    author: BOT_USER,
                    text: 'An error has occurred and I was unable to complete your request. Please try again.'
                  }
                ]
              }));
            }
          )
        )
      )
    );
  });

  readonly sendFeedback = this.effect((isHelpful$: Observable<boolean>) => {
    return isHelpful$.pipe(
      withLatestFrom(this.selectSendFeedbackRequest$),
      exhaustMap(([isHelpful, request]) =>
        this.chatBotService
          .sendFeedback({ ...request, responseFeedback: isHelpful })
          .pipe(
            tapResponse(
              () => {
                this.phoenixToastService.showSuccessToast(
                  'Thank you. Your feedback has been recorded.'
                );
                this.patchState((state) => ({
                  messages: [
                    ...state.messages.map((message) => {
                      if (
                        message.suggestedActions?.some(
                          (action) => action.type === ActionTypes.Feedback
                        )
                      ) {
                        message.suggestedActions = [
                          ...message.suggestedActions.filter(
                            (action) => action.type !== ActionTypes.Feedback
                          )
                        ];
                        return message;
                      }
                      return message;
                    })
                  ]
                }));
              },
              () => {
                this.phoenixToastService.showErrorToast(
                  'An error occurred while recording your feedback. Please try again.'
                );
              }
            )
          )
      )
    );
  });

  expireChatToken$ = this.effect(() => {
    return this.selectAppAuth$.pipe(
      withLatestFrom(this.selectChatTokenExpiration$),
      filter(([, tokenExpiration]) => !!tokenExpiration),
      switchMap(([, tokenExpiration]) => {
        return timer(tokenExpiration).pipe(
          tap(() => this.patchState({ chatAuthentication: undefined }))
        );
      })
    );
  });
}
