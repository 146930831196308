import { SubscriptionFilterKey } from './../models/enum/subscription-filter-key';
import { DataSubscriptionApiModel } from './../models/data-subscription-api-model';
import { environment } from './../../../../environments/environment';
import { DataSubscriptionPayload } from './../models/data-subscription-payload';
import { map, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataExchangeFilter } from './../models/data-exchange-filter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Payload } from '../../../models/payload';
import { ReportType } from '../../reporting/models/enum/report-type.enum';
import { RetailerArea } from '../models/retailer-area';
import { DataExchangeSupplierFilter } from '../models/data-exchange-supplier-filter';
import { SortField } from '../models/enum/sort-field';
import { SortFieldSupplier } from '../models/enum/sort-field-supplier';
import { EsnSubscription } from '../models/esn-subscription';
import { Supplier } from '../models/supplier';
import { User } from '../models/user';
import { CancelEsnSubscriptionRequest } from '../models/cancel-esn-subscription-request';
import { UpsertEsnSubscriptionRequest } from '../models/upsert-esn-subscription-request';
import { ISupplier } from '@app/modules/messages/models/ISupplier';

@Injectable({
  providedIn: 'root'
})
export class DataExchangeService {
  private _url = `${environment.bimlApi}/api-supplier-analytics`;
  private usersMicroservice: string = environment.usersMicroservice;
  private _shouldReturnData = true;

  constructor(
    private _httpClient: HttpClient
  ) {}

  getEsnListByUserApi(email: string): Observable<Payload<ISupplier[]>> {
    return this._httpClient.get<Payload<ISupplier[]>>(this.usersMicroservice + email + '/suppliers').pipe(retry(2));
  }

  private toApiModel(payload: DataSubscriptionPayload): DataSubscriptionApiModel {
    return {
      ...payload,
      filter: payload.filter.map(filter => ({
        key: SubscriptionFilterKey.toString(filter.key),
        value: filter.value.join(',')
      }))
    };
  }

  private toPayload(apiModel: DataSubscriptionApiModel): DataSubscriptionPayload {
    return {
      ...apiModel,
      filter: apiModel.filter.map(filter => ({
        key: SubscriptionFilterKey.parse(filter.key),
        value: filter.value.split(',').filter(a => a !== '')
      }))
    };
  }

  getSubscriptions(
    filter: DataExchangeFilter
  ): Observable<Payload<Array<DataSubscriptionPayload>>> {
    return this._httpClient.get<Payload<Array<DataSubscriptionApiModel>>>(
      `${this._url}/subscription`,
      {
        params: {
          pageCount: filter.take.toString(),
          pageIndex: (filter.skip / filter.take).toString(),
          orderDirection: this.mapSortDirection(filter.sort.dir),
          sortField: this.mapSubscriptionsSortField(filter.sort.field),
          includeAvailableCount: filter.includeAvailableCount.toString(),
          reportType: ReportType.External
        }
      }
    ).pipe(
      map(subscriptions => ({
        ...subscriptions,
        data: subscriptions.data.map(this.toPayload)
      }))
    );
  }

  getSuppliersByEmail(filter: DataExchangeSupplierFilter, email: string): Observable<Payload<Supplier[]>> {
    const url = `${environment.usersMicroservice}/${email.toLowerCase()}/suppliers`;
    const requestParams = {
      pageCount: filter.take.toString(),
      pageIndex: (filter.skip / filter.take).toString(),
      orderDirection: this.mapSortDirection(filter.sort.dir),
      sortName: this.mapSupplierSortField(filter.sort.field),
    };
    return this._httpClient.get<Payload<Supplier[]>>(url, { params: requestParams });
  }

  getSuppliersSubscriptions(esns: string[], ebn: string, filter: DataExchangeSupplierFilter): Observable<Payload<EsnSubscription[]>> {
    const url = `${environment.bimlApi}/api-broker-analytics/esn-subscription`;
    const requestParams = {
      esn: esns.join(','),
      ebn: ebn,
      pageCount: filter.take.toString(),
    };
    return this._httpClient.get<Payload<EsnSubscription[]>>(url, { params: requestParams });
  }

  getUserData(email: string) {
    return this._httpClient.get(
      `${environment.usersMicroservice}/${email}/detail`);
  }

  deleteSubscriptions(
    ids: number[]
  ): Observable<DataSubscriptionPayload[]> {
    return this._httpClient.delete<DataSubscriptionPayload[]>(
      `${this._url}/subscription/${ids.join(',')}`
    );
  }

  createSubscription(subscription: DataSubscriptionPayload): Observable<DataSubscriptionPayload> {
    return this._httpClient.post<DataSubscriptionApiModel>(
      `${this._url}/subscription`,
      this.toApiModel(subscription)
    ).pipe(
      map(subscriptionResponse => this.toPayload(subscriptionResponse))
    );
  }

  getRetailerAreaNames(): Observable<RetailerArea[]> {
    return this._httpClient.get<RetailerArea[]>(
      `${this._url}/retailer-area-names`,
      {
        params: {
          includeId: 'true'
        }
      }
    ).pipe(
      map(names => {
        names.sort();
        return names;
      })
    );
  }

  getListOfEmailsForSupplier(esn: string): Observable<string[]> {
    const url = `${environment.usersMicroservice}${esn}/users`;
    return this._httpClient.get<Payload<User[]>>(url).pipe(
      map(response => response.data.map(user => user.email))
    );
  }

  updateSubscriptionRecipients(customerProfileId: string, request: any): Observable<any> {
    const url = `${environment.bimlApi}/api-broker-analytics/subscription/${customerProfileId}`;
    return this._httpClient.patch(url, request);
  }

  cancelEsnSubscriptions(request: CancelEsnSubscriptionRequest): Observable<any> {
    const url = `${environment.bimlApi}/api-broker-analytics/cancel-esn-subscription`;
    return this._httpClient.put(url, request);
  }

  upsertEsnSubscriptions(request: UpsertEsnSubscriptionRequest): Observable<any> {
    const url = `${environment.bimlApi}/api-broker-analytics/upsert-esn-subscription`;
    return this._httpClient.patch(url, request);
  }

  // temporary call to BI to get DPI data. this should be removed/refactored once DPI data is fully integrated into CONNECT microservices
  getBrandsBySupplier(esn: string): Observable<any[]> {
    return this._httpClient.get<any[]>(`${this._url}/brands/${esn}`);
  }

    // temporary call to BI to get DPI data. this should be removed/refactored once DPI data is fully integrated into CONNECT microservices
    getProductsBySupplier(esn: string): Observable<any[]> {
      return this._httpClient.get<any[]>(`${this._url}/products/${esn}`);
    }

  private mapSortDirection(direction: string): string {
    return direction === 'asc' ? '0' : '1';
  }

  private mapSubscriptionsSortField(field: string): SortField {
    switch (field) {
      case 'reportNameDisplay':
        return SortField.ReportName;
      case 'recurrence':
        return SortField.Recurrence;
      case 'daysView':
       return SortField.Days;
      case 'dateCreatedView':
        return SortField.Created;
      case 'supplierName':
      default:
        return SortField.Supplier;
    }
  }

  private mapSupplierSortField(field: string): SortFieldSupplier {
    switch (field) {
      case 'name':
      case 'supplierName':
        return SortFieldSupplier.Supplier;
      case 'status':
        return SortFieldSupplier.SubscriptionStatus;
      case 'startDate':
        return SortFieldSupplier.StartDate;
      case 'endDate ':
        return SortFieldSupplier.EndDate;
      case 'recipients':
        return SortFieldSupplier.Recipients;
      case 'daud':
        return SortFieldSupplier.DayOfWeek;
      case 'esn':
        return SortFieldSupplier.ESN;
      default:
        return SortFieldSupplier.Supplier;
    }
  }

}
