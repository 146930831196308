import { Component, ViewEncapsulation } from '@angular/core';
import {
  SendMessageEvent,
  ConversationalUIModule,
  ExecuteActionEvent
} from '@progress/kendo-angular-conversational-ui';
import { CommonModule } from '@angular/common';
import { FloatingActionButtonModule } from '@progress/kendo-angular-buttons';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { ChatBotStore } from './chat-bot.store';
import { Router } from '@angular/router';
import { ActionTypes } from './interfaces';
import { AvatarModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-chat-bot',
  standalone: true,
  imports: [
    CommonModule,
    ConversationalUIModule,
    FloatingActionButtonModule,
    PopoverModule,
    AvatarModule
  ],
  providers: [ChatBotStore],
  templateUrl: './chat-bot.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './chat-bot.component.scss'
})
export class ChatBotComponent {
  user$ = this.chatBotStore.selectUser$;
  messages$ = this.chatBotStore.messages$;

  constructor(
    private readonly chatBotStore: ChatBotStore,
    private router: Router
  ) {}

  addUserMessage(e: SendMessageEvent): void {
    this.chatBotStore.addUserMessage(e.message);
  }

  onPopoverShown(): void {
    this.chatBotStore.patchState({ isChatOpen: true });
  }

  onPopoverHidden(): void {
    this.chatBotStore.patchState({ isChatOpen: false });
  }

  onCustomSuggestedAction(e: ExecuteActionEvent): void {
    switch (e.action.type) {
      case ActionTypes.Link:
        if (e.action.value['isExternal'] === true) {
          window.open(e.action.value['url'], '_blank');
        } else {
          this.router.navigateByUrl(e.action.value['url']);
        }
        break;
      case ActionTypes.Feedback:
        this.chatBotStore.sendFeedback(e.action.value);
        break;
      default:
        break;
    }
  }
}
