import { ActionCreatorProps, createAction, NotAllowedCheck } from '@ngrx/store';

export function createTypedAction(groupName: string, actionName: string): ReturnType<typeof createAction>;
export function createTypedAction<P extends object>(groupName: string, actionName: string, props: ActionCreatorProps<P> & NotAllowedCheck<P>): ReturnType<typeof createAction>;

export function createTypedAction(groupName: string, actionName: string, props?: ActionCreatorProps<any> & NotAllowedCheck<any>) {
  if (props) {
    return createAction(`[${groupName}] ${actionName}`, props);
  } else {
    return createAction(`[${groupName}] ${actionName}`);
  }
}
