import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShowCustomerComboboxStore } from './show-customer-combobox.store';
import { ShowEligibleCustomer } from '@app/models/show-models';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { LabelModule } from '@progress/kendo-angular-label';

@Component({
  selector: 'app-show-customer-combobox',
  standalone: true,
  imports: [CommonModule, ComboBoxModule, PhoenixIndicatorsModule, LabelModule],
  templateUrl: './show-customer-combobox.component.html',
  styleUrls: ['./show-customer-combobox.component.scss'],
  providers: [ShowCustomerComboboxStore]
})
export class ShowCustomerComboboxComponent extends DestroyableDirective {
  @Input()
  set showId(value: string | null) {
    this.store.setShowId(value);
  }

  @Input()
  label: string = 'Retailers';

  @Input()
  placeholder: string = 'Search by Retailer Name or DC Customer Number';

  @Input()
  automationId: string = 'select-customer-input';

  @Output()
  customerSelected: EventEmitter<ShowEligibleCustomer> = new EventEmitter();

  state$ = this.store.state$;
  longListCount = 25;

  constructor(private store: ShowCustomerComboboxStore) {
    super();

    this.store.selectedCustomer$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.customerSelected.emit(val);
      });
  }

  handleFilterCustomer(value: string) {
    this.store.setSearch(value);
  }

  handleCustomerSelected(value: ShowEligibleCustomer) {
    this.store.setSelectedCustomer(value);
  }
}
