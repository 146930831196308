import { Component } from '@angular/core';
import { DestroyableDirective } from '../../../../abstract/destroyable';
import { GoogleTagManagerFacadeService, PendoFacadeService } from '@kehe/promotional-roadmap-lib';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { takeUntil } from 'rxjs/operators';
import { PendoService } from '../../../../pendo/pendo.service';

@Component({
  selector: 'app-promotional-roadmap-page',
  templateUrl: './promotional-roadmap-page.component.html'
})
export class PromotionalRoadmapPageComponent extends DestroyableDirective {
  constructor(
    private _gtmFacade: GoogleTagManagerFacadeService,
    private _gtmService: GoogleTagManagerService,
    private _pendoFacade: PendoFacadeService,
    private _pendoService: PendoService
  ) {
    super();
    this._gtmFacade.pushTagEvent
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this._gtmService.pushTag(event);
      });
    this._pendoFacade.trackEvent
      .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          this._pendoService.trackEvent(event.eventName, event.eventProperties);
        });
    this._pendoFacade.updateAccount
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this._pendoService.updateAccount(event.updatedAccount)
      })
  }
}
